import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/library",
    name: "library",
    component: () => import("@/modules/library/views/Library.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Biblioteca",
      guards: ["acl"],
      slug: "product.view",
    },
  },
  {
    path: "/library/type/:type",
    name: "library.type",
    component: () => import("@/modules/library/views/Library.vue"),
    props: {
      filterType: true,
    },
    meta: {
      middleware: [AuthMiddleware],
      title: "Produtos",
      featureSlug: "product",
    },
  },
  {
    path: "/library/:id",
    name: "library.product",
    component: () => import("@/modules/library/views/LibraryProduct.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      title: "Biblioteca",
      guards: ["acl"],
      slug: "product.view",
    },
  },
  {
    path: "/external/library",
    name: "library.external",
    component: () => import("@/modules/library/views/Library.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Biblioteca externa",
      guards: ["acl"],
      slug: "product.view",
    },
  },
  {
    path: "/external/library/:id",
    name: "library.product.external",
    component: () => import("@/modules/library/views/LibraryProduct.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      title: "Biblioteca externa",
      guards: ["acl"],
      slug: "product.view",
    },
  },
];
