import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Home",
    },
  },
  {
    path: "/dashboards/config",
    name: "dinamic.dashboard.config",
    component: () => import("../views/DinamicDashboardConfig.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Dashboards",
      layout: 'config'
    },
  },
  {
    path: "/dashboards/:id",
    name: "dinamic.dashboard",
    component: () => import("../views/DinamicDashboard.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Dashboard",
      containerStyle: {
        height: '100%'
      }
    },
  },

];
